.appliedFilterContainer {
  background-color: rgb(0, 140, 255);
  border-radius: 20px;
  height: 50px;
  margin: 5px;
}

.appliedFilterContent {
  display: flex;
  align-items: center;
  margin-left: 5px;
  margin-right: 5px;
  height: 100%;
}

.appliedFilterText {
  color: white;
  font-size: 20px;

}

.iconContainer {
  position: relative;
  float: right;
  right: 29px;
  top: -16px;
  .iconElement {
    position: absolute;
    font-size: 32px;
  }

  .closeIconElement {
    color: #c73b3b;
    cursor: pointer;
  }
}

.iconBackgroundSmall {
  background-color: white;
  width: 18px;
  height: 15px;
  right: 12px;
  position: absolute;
}
