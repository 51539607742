
.stepIcon{
    width:100%;
    display:flex;
    justify-content: center;
}


.stepRemains{
    background-color: rgb(28, 74, 0);
    color:white;
    border-radius:100%;
    width:40px;
    height:40px;
    display:flex;
    justify-content: center;
    align-items: center;
}

.bottomContainer{
    margin-top:15px;
    margin-bottom:15px;
}
.label{
  text-align: center;
  font-weight:300;
  font-size:24px;
  margin-top:20px;
  margin-bottom:20px;
}
.remainsProductContainer{
    margin:10px;
    display:flex;
    justify-content: center;
    border-style:solid;
    border-width:2px;
    border-radius:16px;
    border-color:rgb(28, 74, 0);
  }

  .remainsCloseBtn{
    cursor: pointer;
    position: absolute;
    right: -2px;
    background-color: #b52929;
    width: 39px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 8;
    top: -1px;
    border-radius: 0px 15px 0px 0px;
    font-weight: 900;
  }

  .remainsContainerTop{
    display:flex;
    justify-content: center;
    width:100%;
    background-color: rgb(28, 74, 0);
    color:white;
    border-radius:16px 16px 0px 0px;
    position:relative;
    top:-1px;
    font-size:18px;
    padding-top:5px;
    padding-bottom:5px;
  }


  .inputContainerQtyWorked{
    display:flex;
    justify-content: center;
    margin-bottom:5px;
    margin-top:10px;
  }

  .addrembtn{
    height:55px;
    position:relative;
    top:-4px;
  }

  .remainsBtnStyle{
    button{
      background-color: red;
    }

  }