.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
}
.receivingOrdersContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.objsSelection {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 950px;
}

.objElement {
  margin: 5px;
}
.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 0px;
    width: 100px;
    height: 100px;
  }
}

.scannerContainer{
  display:flex;
  align-items: center;
  justify-content: center;
  color:black;
  
}
.scannerDeviceElemTitle{
  color:black;
  font-size:30px;
  display:flex;
  justify-content: center;
  align-items: center;
  height:100%;
}

.scannerContentBtn{

}

.elemElement {
  color: rgba(61, 61, 61, 0.15);

  width: 284px;
  height: 100px;

  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}
.settingsContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}

.customSearchBarContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.searchIconStyles {
}

.progressBarContainer {
  position: absolute;
  bottom: 10px;
  width: 225px;
}

.appliedFiltersContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.orderNameCodeContainer {
  color: black;
  align-items: center;
}

.orderNameCode {
  margin-left: 5px;
}

.orderLabel {
  display: flex;
  justify-content: flex-start;
}
.orderTopLabel {
  display: flex;
  justify-content: flex-start;
}

.singleLineOverFlowElipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.twoLineElipsis{
  height: 36px; /* Set height to fit two lines of text */
      overflow: hidden; /* Hide overflow text */
      text-overflow: ellipsis; /* Add ellipsis at the end */
      display: -webkit-box; /* Create a flexible box */
      -webkit-line-clamp: 2; /* Limit to 2 lines */
      -webkit-box-orient: vertical; /* Set the box orientation to vertical */
      white-space: normal; /* Allow text to wrap */
}


.familiesLabel{
  font-size:13px;
  font-weight:900;
}


.limitedFamiliesContainer{
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}