.popupTitle{
    display:flex;
    justify-content: center;
    font-size:20px;
    margin-top:10px;
}

.filteringOptionsContainer{
    display:flex;
    justify-content: center;
}

.selectedParticipantsContainer{
    width:100%;
    background-color: #c0c0c0;
    display:flex;
    justify-content: center;
     padding:10px;
}

.workersContainer{
    display:flex;
    justify-content: center;
    flex-wrap:wrap;
    margin-bottom:50px;
    margin-left:5px;
    margin-right:5px;
}

.workerContainer{
    margin:10px;
    border-style:solid;
    border-color:black;
    border-width:1px;
    border-radius:10px;
    padding:10px;

}

.workerContainer:hover{
    background-color:rgb(228, 228, 238);
    cursor:pointer;
}

.selectedWorker{
    background-color:rgb(178, 178, 215);
    border-width:2px;
}

.quickTools{
    display:flex;
    justify-content: center;
}

.assignedLabel{
    color:rgb(89, 144, 183);
    cursor:pointer;
    text-decoration: underline;
    margin-bottom:15px;
}

.bottomContainer{
    display:flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    min-height:110px;
    height:18%;
}

.totalWorkersContainer{
    margin:15px;
    border-style:solid;
    border-radius:10px;
    border-width:1px;
   
    display:flex;
    justify-content: center;
    align-items: center;
    padding-left:10px;
    padding-right:10px;
   
}
.disabledWorkerBtn{
    background-color: gray;
    
}
.disabledWorkerBtn:hover{
    background-color: gray;
    cursor:not-allowed;
}
.totalWorkersCount{
    font-size:30px;
    margin:5px;
}

.workersNoneSelected{
    color:black;
    border-color:black;
}

.workersSomeSelected{
    border-color:#7070bb;
    color:#7070bb;
    background-color: #e9efee;
}

.confirmButton{
    height:70px;
}