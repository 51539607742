.content{
    margin:20px;
}

.poTools{
    display:flex;
    justify-content: center;
}

.toolBtn{
    width:260px;
}