.content {
  padding: 5px;
  background-color: white;
  width: 90vw;
  height: 70vh;
  min-width: 380px;
  min-height: 320px;
  /* max-width:400px;*/
  max-height: 642px;
  overflow: auto;
}

.selectorElem {
  margin: 13px 0px 0px 0px;
  min-width:200px;
}

.label {
  text-align: center;
}

.properSubtitle{


  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;

  z-index:2;
  padding-top:10px;
  padding-bottom:10px;
  color:white;
}

.addBtnContainer {

  display: flex;
  justify-content: center;
}
.trashIcon{
  margin-right:10px;
  color:white;
  background-color: rgb(133, 116, 97);
  width:30px;
  height: 30px;
  border-radius:100%;
  display:flex;
  justify-content: center;
  align-items: center;
 
}

.iconTypeCons{

}
.pvContainer {
  margin-top: 10px;
}

.tableArea {
  display: flex;
  justify-content: center;

  @media (max-width: 685px) {
    width: 100%;
    justify-content: center;
    display: flex;
  }

  @media (min-width: 686px) {
    width: 100%;
  }

  margin-bottom:20px;
}
/*
        background-color: var(--ion-color-primary);  
        color: var(--ion-color-primary-contrast);
        */
.consumptionsTableContainer {
  /*width: 100%;*/


  display: table;
  .consumptionsTableBody {
    display: table-row-group;
  }
  .consumptionsRow {
    display: table-row;
  }

  .consumptionsLeftHeader {
    border-radius: 5px 0px 0px 0px;
  }
  .consumptionsRightHeader {
    border-radius: 0px 5px 0px 0px;
  }
  .consumptionsBodyCellHeader {
    display: table-cell;
    border: 1px solid var(--ion-color-primary);

    padding: 8px;
    line-height: 1.42857143;
    vertical-align: top;
    background-color: var(--ion-color-primary);
    color: var(--ion-color-primary-contrast);
    
  }

  .qtyErrorContainer{
    background-color: rgb(241, 206, 206);
  }
  .consumptionsBodyCell {
    display: table-cell;
    border: 1px solid var(--ion-color-primary);
    padding: 8px;
    line-height: 1.42857143;
    vertical-align: middle;
  }

  .consumptionsCellProduct{
    vertical-align: top;
  }
}

.noConsumptionAv{
  position: relative;
  top: 10px;
  font-size: 26px;
}

.consumptionProductName {

  font-size: 19px;
  margin: 5px;
  min-width: 100px;
}

.smallLabel{
  font-size:13px;
}

.consumptionLine{
  border-radius:10px;
  border-width:1px;
  border-style:solid;
  border-color:black;
  margin:3px;
  padding:3px;
  text-align: center;
  background-color: #daedda;
}

.addedQty{
  color:green;
}

@media screen and (max-width: 540px){
  .consumptionProductName {
    font-size: 15px;
    margin: 5px;
  }
  
}

.consumptionVarsContainer {
  margin: 5px;
}

.consumptionVarsTitle {
  font-weight: 600;
}

.consumptionsQtyContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-width: 100px;
  .consumptionsQty {
    font-size: 40px;
    width:100%;
  }
}

.popupBottomFixedDiv {
  position: fixed;
  bottom: 0;
  width: 100%;
}

.qtyFieldContainer {
  input {
    width: 100px;
    font-size: 20px;
    text-align: center;
  }
}

.consumptionsProductCol {
  max-width: 200px;
}

.consumptionsParameterizedVariablesLabel {
  font-size: 12px;
}
.consumptionsParameterizedVariablesAndValues {
  font-size: 12px;
}

.notEnoughtAvailableQtyLabel {
  color: var(--ion-color-danger);
}

.lotToolsContainer{
  display:flex;
  justify-content: center;
}

.lotToolBtn{
  background-color: #ecf9ff;
  display:flex;
  border-radius:5px;
  border-style:solid;
  border-width:1px;
  align-items: center;
  margin:5px;
  padding:5px;
}

.lotToolBtn:hover{
  cursor:pointer;
  background-color: #c1e9d8;
}

.lotToolBtnExistingLots{
  background-color: #ffffec;
  display:flex;
  border-radius:5px;
  border-style:solid;
  border-width:1px;
  align-items: center;
  margin:5px;
  padding:5px;
}

.lotToolBtnExistingLots:hover{
  cursor:pointer;
  background-color: #e4e9c1;
}


.qtyConsProductContainer{
  display:flex;
  align-items: center;
  justify-content: center;
  cursor:pointer;
  color:rgb(104, 95, 156);
  border-width:1px;
  border-color:rgb(104, 95, 156);
  padding:5px;
  border-style:solid;
  border-radius:5px;
  width:100%;
}
.qtyConsProductContainer:hover{
  background-color: rgb(232, 229, 246);
}
