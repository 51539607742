.pageTitle {
  display: flex;
  justify-content: center;
  font-size: 20px;
  margin-top: 20px;
  margin-left: 15px;
}
.shippingOrdersContainer {
  display: flex;
  justify-content: center;
  margin-top: 30px;
  margin-bottom: 95px;
}
.latestScannedRow{
  background-color: rgb(255, 255, 201);
}
.shippingOrdersSelection {
  padding-bottom: 150px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width:1170px;
  //overflow:auto;
  /*height:100%;
  
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(190px, 1fr));
    grid-gap: 73px;
    @media all and (max-width: 450px) {
      margin-top: 20px;
      padding-bottom: 20px;
    }
    @media all and (min-width: 450px) {
      margin-top: 17px;
      width: 100%;
      margin-left: 20px;
      margin-right: 20px;
    }*/
}

.shippingOrderElement {
  margin: 12px;
}

.barcodeIconBg{

  display: flex;
  justify-content: center;


}
.elemBackgroundContainer {
  display: flex;
  justify-content: center;

  width: 100%;


  svg {
    position: absolute;
    top: 10px;
    right:10px;
    width: 40px;
    height: 40px;
  }
}

.elemElement {
  color: rgba(61, 61, 61, 0.15);

  width: 284px;
  height: 195px;

  //width:20vw;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.246);
  border-radius: 15px 15px 15px 15px;
  margin-right: 10px;
}

.elemElement:hover {
  background-color: rgba(78, 195, 195, 0.123);
  cursor: pointer;
  /*.elemElementTitle{
          display:none;
      }
  
      .elemElementDescription{
          display:none;
      }*/
}

.elemElementDescription {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.goBackLabelContainer {
  display: flex;
  justify-content: center;
}

.errorArea {
  display: flex;
  justify-content: center;
}

.pendingActiveProductions {
  background-color: rgba(38, 124, 158, 0.212);
}

.simultaneousOperations {
  background-color: rgba(38, 187, 142, 0.329);
}

.btnsContainer {
  margin: 10px;
  padding-top: 50px;
}
.settingsContainer {
  display: flex;
  justify-content: space-evenly;
  margin-top: 50px;
}

.customSearchBarContainer {
  margin-left: 10px;
  margin-right: 10px;
}

.searchIconStyles {
}

.totalWeightBottomContainer{
  height: 20%;
  max-height: 160px;
}
.progressBarContainer {
  position: absolute;
  bottom: 10px;

  width:89%;
}

.appliedFiltersContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: 15px;
}

.orderNameCodeContainer {
  color: black;
  align-items: center;
}

.orderNameCode {
  margin-left: 5px;
}

.creationDateContainer {
  position: absolute;
  bottom: 25px;
  color: black;
}
.orderLabel {
  display: flex;
  justify-content: flex-start;
}
.orderTopLabel {
  display: flex;
  justify-content: flex-start;
  font-size:13px;
}
.descContainer{
  margin-left: 5px;
}
.singleLineOverFlowElipsis {
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.pvContainer{
  font-size:13px;
}

.doubleLineOverFlowElipsis{
  overflow: hidden;
text-overflow: ellipsis;
display: -webkit-box;
-webkit-line-clamp: 2;
-webkit-box-orient: vertical;
}

.orderInfoContainer {
  margin: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.orderInfo {
  background-color: #585e89;
  color: white;
  border-radius: 5px;
  min-width: 255px;
  max-width: 670px;
}

.orderInfoContent {
  margin: 20px;
}
.infoAndSelection {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.shippingLocationContainer {
  margin: 5px;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

.shippingLocation {
  background-color: rgb(40, 40, 131);
  color: white;
  border-radius: 5px;
  min-width: 274px;
  max-width: 670px;
}

.shippingLocationContent {
  margin: 20px;
}

.rlocTitle {
  font-size: 25px;
}

.modifyLocationBtn {
  margin: 20px;
}

.orderCompletedContainer{
  color:rgb(50, 132, 50);
  display:flex;
  justify-content: center;
  text-align: center;
}

.sendAllTitle {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: black;
  font-size: 30px;
  font-weight: 900;
}

.sendAllMatched {
  border-width: 4px;
}
.sendAllElem {
  background-color: rgba(38, 158, 112, 0.212);
  margin: 12px;
}

.scannerElem {
  background-color: rgba(102, 119, 136, 0.383);
  margin: 12px;
}

.confirmationBottomContainer {
  background-color: white;
  width: 100%;
  position: fixed;
  bottom: 0px;
  z-index: 2;
  height: 175px;
  display: flex;
  justify-content: center;
}

.confirmButton {
  min-height: 80px;
  height: 5vh;
  /*width:60vw;*/
  width: 100%;
  max-width: 250px;
  min-width: 175px;
  font-size: 25px;
  margin: 10px;
}

.lineBreaker {
  width: 100%;
  height: 2px;
  background-color: rgba(88, 86, 86, 0.096);
}

.confirmationBottomContent {
  width: 100%;
}

.centeredElem {
  display: flex;
  justify-content: center;
}
.cornerBtnsContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap:wrap-reverse;
  margin-right: 5px;
  margin-left: 5px;
}

.topPageTitleBtnsContainer {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.arrowSpaceFiller {
  width: 80px;
  height: 80px;

  /*
  @media all and (max-width: 450px) {
    display: none;
  }*/
}

.locDiv {
  margin-top: 5px;
}

.addingQtyMobile {
  position: absolute;
  bottom: 1px;
  width: 83%;
  justify-content: center;
  display: flex;
  color: green;
  font-weight: 900;
}
.addingQtyTable {

  bottom: 1px;
  width: 83%;
  justify-content: center;
  display: flex;
  color: green;
  font-weight: 900;
  font-size:20px;
}

.incompleteDefinedStockLocation {
  font-weight: 900;
  margin: 2px;
  color: rgb(179, 134, 37);
}


.removeParagraphsMargins{
  p {
      margin: 0;
  }
}

.pvvs{
  background-color:#cecece;
  margin-bottom:5px;
  padding:5px;
  text-align: center;
}
.totalWeightContainer{
  display:flex;
  justify-content: center;

}

.totalWeightElementsContainer{
  margin:20px;
  display:flex;
  align-items: center;
}
.totalWeightSeparation{
  margin-left:10px;
  margin-right:10px;
}

.weightConfirmationLabel{
  display:flex;
  justify-content: center;
  font-size:20px;
  margin:20px;
}
.packageWeightContainer{
  padding:10px;
  margin-top:20px;
  display:flex;
  justify-content: center;
  align-items: center;
  font-size:30px;
  border-width:1px;
  border-radius:10px;
  border-color:rgb(140, 140, 227);
  border-style: solid;
  color:rgb(81, 81, 211);
}

.packageWeightContainer:hover{
  background-color: rgb(220, 249, 220);
  cursor:pointer;
}
.packageWeightConfirmed{
  border-width:2px;
  color:rgb(49, 111, 42);
  border-color:rgb(61, 93, 59);
}
.tableViewMode{
  display:flex;
  justify-content: center;
  width:100%;
  margin-bottom:200px;
}
.tableFormatContainer{
  margin:20px;
  max-width:700px;
  width:100%;
}

.tableProgressContainer{
  position:relative;
  width:200px;
}

.displayViews{
  display:flex;
  justify-content: center;
}

.descriptionShow{
    color: black;
    background-color: #fffea5;
    font-size: 20px;
    padding:10px;
}